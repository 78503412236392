import { useEffect, useState } from "react";

import Project from "./Project";
import { IProject } from "../../api/Interfaces"
import {
    Column,
    Img,
    List,
    ListTitle,
    Row,
    StyledDiv,
    MapText,
} from "./styled";
import '../../assets/css/Loader.css'
import ToArrow from "../../assets/svg/ToArrow.svg";
import CameraIconDark from "../../assets/svg/CameraIconDark.svg";
import Icon360 from "../../assets/svg/Icon360.svg";
import Light360 from "../../assets/svg/Light360.svg";

import './Projects.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Timeout } from "../public/Login";
// import MapImg from "../../assets/svg/mapImg.svg"
import MapImg from "../../assets/svg/mapImg2.svg"

const API_URL: string | undefined = process.env.REACT_APP_API_URL;

export default function Projects() {
    const [projectList, setProjectList] = useState<Array<IProject>>([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            const response = await fetch(`${API_URL}/projects`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': "application/json",
                },
                signal: Timeout(7).signal
            });
            const data = await response.json();
            setProjectList(data);
            if (response.ok) {
                setLoaded(true);
            }
        })().catch(console.error);
    }, []);

    return loaded ? (
        <StyledDiv css="display: flex;
                        justify-content: space-between;
                        margin:auto; margin-left: 0px;
                        ">
            <Column xs={5} css="">
                <ListTitle css="margin-top: 30px; text-align: left; margin-left: 15%">Projects</ListTitle>
                <StyledDiv css="background-image:
                                linear-gradient(180deg, rgba(50, 54, 68, 1) 55%,
                                                rgba(50, 54, 68, 0.9) 60%,
                                                rgba(50, 54, 68, 0.8) 90%,
                                                rgba(50, 54, 68, 0) 100%);
                                height: 60%;
                                margin: 38px 0 0 15%;
                                padding: 15px;
                                border-radius: 15px;
                                ">
                    <List css="">
                        {projectList.map((project, index: number) => (
                            <div key={project.id}>
                                <Project project={project} initialOpen={!index} />
                            </div>
                        ))}
                    </List>
                </StyledDiv>
            </Column>
            <Column xs={7} css="">
                <StyledDiv css="background-image:
                                linear-gradient(180deg, rgba(50, 54, 68, 1) 55%,
                                                rgba(50, 54, 68, 0.9) 60%,
                                                rgba(50, 54, 68, 0.8) 90%,
                                                rgba(50, 54, 68, 0) 100%);
                                height: 60%;
                                margin: 138px 10%;
                                padding: 25px;
                                border-radius: 15px;
                                ">
                    <Row css="
                            margin:0px;
                            padding: 10px;
                            display: flex;
                            justify-content: space-between;
                    ">
                        <Column xs={3} md={3} lg={3}>
                            <MapText css="                                
                                font-weight: 800;
                                font-size: 15px;
                                color: #9AA5B5;
                            ">From</MapText>
                            <MapText css="
                            font-weight: 800;
                            font-size: 18px;
                            ">Eksjö</MapText>
                        </Column>
                        <Column xs={2} md={2} lg={2} css="margin-top: 22px;">
                            <Img src={ToArrow} />
                        </Column>
                        <Column xs={7} md={7} lg={7}>
                            <MapText css="                                
                                font-weight: 800;
                                font-size: 15px;
                                color: #9AA5B5;
                            ">To</MapText>
                            <MapText css="
                            font-weight: 800;
                            font-size: 18px;
                            ">Nässjö</MapText>
                        </Column>
                    </Row>
                    <Row css="margin: 10px; padding-left: 10px;
                                background-color: rgba(39, 42, 55, 1);
                                min-width: 445px;
                                border-radius: 15px;" >
                        <Column xs={3} md={3} lg={3} css="padding: 10px;">
                            <MapText css="
                                    font-weight: 800;
                                    font-size: 10px;
                                    color: #9AA5B5;
                                ">Date</MapText>
                            <MapText css="
                                    font-weight: 800;
                                    font-size: 12px;
                                ">11th February, 2022</MapText>
                        </Column>
                        <Column xs={4} md={4} lg={4} css="padding: 10px;">
                            <MapText css="
                                    font-weight: 800;
                                    font-size: 10px;
                                    color: #9AA5B5;
                                ">Track Sections</MapText>
                            <MapText css="
                                    font-weight: 800;
                                    font-size: 12px;
                                ">831, 817</MapText>
                        </Column>
                        <Column xs={5} md={5} lg={5} css="padding: 0px;
                                            display: flex;
                                            justify-content: space-between;">
                            <Column xs={4} md={4} lg={4} css="padding: 10px;">
                                <MapText css="
                                        font-weight: 800;
                                        font-size: 10px;
                                        color: #9AA5B5;
                                    ">Camera</MapText>
                                <Img css="margin-left: 8px;" src={CameraIconDark} />
                            </Column>
                            <Column xs={4} md={4} lg={4} css="padding: 10px;">
                                <MapText css="
                                        font-weight: 800;
                                        font-size: 10px;
                                        color: #9AA5B5;
                                    ">Lidar</MapText>
                                <Img css="margin-left: 3px;" src={Icon360} />
                            </Column>
                            <Column xs={4} md={4} lg={4} css="padding: 10px;">
                                <MapText css="
                                        font-weight: 800;
                                        font-size: 10px;
                                        color: #9AA5B5;
                                    ">360°</MapText>
                                <Img css="margin-left: 0px;" width="20px" src={Light360} />
                            </Column>
                        </Column>
                    </Row>
                    <Row css="margin: 0px 10px 0 10px;
                                background-color: rgba(39, 42, 55, 1);
                                min-width: 435px;
                                width: 100%-10px;
                                border-radius: 15px;">
                        <Img css="
                                padding: 10px;
                                position: relative;
                                border-radius: 20px;
                                "
                            src={MapImg} width="100%" />
                    </Row>
                </StyledDiv>
            </Column>
        </StyledDiv>
    ) : <div className="loader"><div></div><div></div><div></div><div></div></div>;
}
