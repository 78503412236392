import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

import { IProject } from "../../api/Interfaces";
import ScanSessions from "./ScanSessions";
import { Img, Row, StyledDiv, Text } from "./styled";
import DropDownArrow from "../../assets/svg/dropdownArrow.svg"

const Project = ({ project, initialOpen }: { project: IProject, initialOpen: boolean }) => {
    const [open, setOpen] = useState(initialOpen);
    return (
        <>
            <Row css="
                    margin: 10px 0 0 0;
                    background-color: rgba(39, 42, 55, 1);
                    border-radius: 20px;
                    padding: 10px 0;
                    "
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}>
                <StyledDiv css="
                        display: flex;
                        height: 50px;
                        ">
                    <Text css="
                        font-weight: 800;
                        font-size: 18px;
                        line-height: 50px;
                        text-align: right;
                        margin-left: 15px;
                        "
                    >{project.name}
                    </Text>
                    <Img css="margin:auto; margin-right: 0; "
                        height="30x"
                        src={DropDownArrow} />
                </StyledDiv>
                <Collapse in={open}>
                    <div>
                        <ScanSessions projectID={project.id} />
                    </div>
                </Collapse>
            </Row>

        </>
    )
}
export default Project;
