import React, { useEffect, useState } from "react";
import { SubListItem } from "./styled";

import ScanSession from "./ScanSession";
import { IScanSession } from "../../api/Interfaces";
import { Timeout } from "../public/Login";

const API_URL: string | undefined = process.env.REACT_APP_API_URL;

const ScanSessions = ({ projectID }: { projectID: number }) => {
    useEffect(() => {
        (async () => {
            const response = await fetch(`${API_URL}/scansessions/projects/${projectID}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': "application/json",
                },
                signal: Timeout(10).signal,
            });
            const data = await response.json();
            setScanSessions(data);
        })().catch(console.error);
    }, [])
    const [scanSessions, setScanSessions] = useState<Array<IScanSession>>([]);

    return (
        <div>
            <SubListItem id="example-collapse-text">
                {scanSessions.map((scan) => (
                    <div key={scan.id}>
                        <ScanSession {...scan} />
                    </div>
                ))}
            </SubListItem>
        </div >
    )

}

export default ScanSessions;
