import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/svg/logo.svg"
import Logo2 from "../../assets/svg/logoBottom.svg"
import BG from "../../assets/svg/bgSvg.svg"
import LoginLine from "../../assets/svg/LoginLine.svg"
import posthog from 'posthog-js';

import {
    useNavigate,
} from 'react-router-dom';


import {
    Background,
    ImgOverLay,
    StyledImg,
    LoginFormDiv,
    BigPoint,
    LogInTag,
    RememberMeText,
    StyledButton,
    NoAccoutText,
    Img
} from './styled'

import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    Form,
} from 'react-bootstrap';

const API_URL: string | undefined = process.env.REACT_APP_API_URL;

export const Timeout = (time: any) => {
	let controller = new AbortController();
	setTimeout(() => controller.abort(), time * 1000);
	return controller;
};

async function handleLogin(formData: any) {
    return fetch(`${API_URL}/auth/jwt/login`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
        signal: Timeout(7).signal
    });
}

async function fetchToken() {
    return fetch(`${API_URL}/users/me`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': "application/json",
        },
        signal: Timeout(7).signal
    });
}

export default function Login({ setToken, setTokenStatus, hogger }: any) {

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string | any>(null);

    const navigate = useNavigate();

    const handleSubmit = async (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
        e.preventDefault();
        e.stopPropagation();

        const formData = new FormData();
        formData.set("username", email);
        formData.set("password", password);

        const handleLoginResp = await handleLogin(formData);

        if (handleLoginResp.ok) {
            const fetchTokenResp = await fetchToken();
            if (fetchTokenResp.ok) {
                fetchTokenResp.json().then((body) => { setToken(body); setTokenStatus(true); });
                posthog.capture('sign in', { 'user': email, 'datetime': Date.now().toLocaleString() });
                navigate('/');
            }
            else {
                setError("Token for user doesnt exists")
            }
        }
        else {
            setError("Incorrect email or password, try again or contact us!")
        }
    }

    return (
        <Background css="width: 100%;
                        min-width: 900px;
                        background-attachment: fixed;"
            >
            <Col xs={6} md={6} lg={6}>
                <Navbar bg="" variant="dark" style={{ "margin": "63px 93px", "position": "absolute", "zIndex": "9" }}>
                    <Container>
                        <Navbar.Brand href="/login">
                            <img
                                alt="Atritec"
                                // src="/logo.svg"
                                src={Logo}
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link
                                style={{
                                    "marginRight": "77px",
                                    "marginLeft": "79px",
                                    "fontSize": "20px",
                                    "fontWeight": "800"
                                }}
                                href="https://www.atritec.se/kontakt"
                                target="_blank"
                            >Order</Nav.Link>
                            <Nav.Link
                                style={{
                                    "fontSize": "20px",
                                    "fontWeight": "800"
                                }}
                                href="https://www.atritec.se/kontakt"
                                target="_blank"
                            >Contact</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
                <LoginFormDiv>
                    <Container>
                        <Row>
                            <RememberMeText>
                                Get Started
                            </RememberMeText>
                        </Row>
                        <Row>
                            <LogInTag>Login<BigPoint>.</BigPoint></LogInTag>
                        </Row>
                        <Row>
                            <NoAccoutText>
                                Do not have an account?
                                <a style={{ color: "#1CB797" }}
                                    href="https://www.atritec.se/kontakt"
                                    target="_blank" rel="noreferrer"
                                    > Register</a>
                            </NoAccoutText>
                        </Row>
                        <Container style={{ "paddingLeft": "0px" }}>
                            <Form>
                                    <span style={{ "padding": "3px", "color": "#dc3545", "fontSize": "18px" }}>
                                        {error}</span>
                                
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label style={{ "fontSize": "18px" }}>E-mail
                                    </Form.Label>
                                    <Form.Control
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email" placeholder="Insert email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupPassword">
                                    <Form.Label style={{ "fontSize": "18px" }}>Password</Form.Label>
                                    <Form.Control
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password" placeholder="Insert password" />
                                </Form.Group>
                                <StyledButton
                                    type="submit"
                                    onClick={handleSubmit}
                                >Login
                                </StyledButton>
                            </Form>
                        </Container>

                    </Container>
                </LoginFormDiv>

            </Col>
            <Col className="LoginImgCol" xs={6} md={6} lg={6}>
                <Img css="
                            position: absolute;
                            height: 100vh;
                            left:40%;
                            z-index:6;
                            "
                    alt="" src={LoginLine}
                />
                <ImgOverLay>

                </ImgOverLay>
                <StyledImg css=""
                    alt="Atritec"
                    src={BG}
                    className="d-inline-block align-top"
                />
            </Col>
            <Img css="
                        position: fixed;
                        bottom: 65px;
                        right: 65px;
                        z-index: 9;
                        "
                alt="Atritec"
                src={Logo2}
                className="d-inline-block align-top"
            />
        </Background>
    )
}