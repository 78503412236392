import React, { useEffect } from 'react';
import { useState } from "react";
import posthog from 'posthog-js';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Login from './views/public/Login';
import FourOFour from './views/public/404';

import Layout from "./views/private/Layout"
import Profile from "./views/private/Profile"
import MainViewer from './views/private/MainViewer';
import { Timeout } from "./views/public/Login";

import { ViewerProvider } from "./viewerContext";

if ( (process.env.REACT_APP_POSTHOG_API_KEY) && (process.env.REACT_APP_POSTHOG_API_HOST) )
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {api_host: process.env.REACT_APP_POSTHOG_API_HOST});

// const hogger_key: string | undefined = process.env..toString()

const API_URL: string | undefined = process.env.REACT_APP_API_URL;

const PrivateRoute = ({ children, tokenStatus, setToken, setTokenStatus }: any) => {
  return tokenStatus ? children : <Login setToken={setToken} setTokenStatus={setTokenStatus} />
};

function App() {
  const [tokenStatus, setTokenStatus] = useState<boolean>(false);
  const [token, setToken] = useState<any>();

  useEffect(() => {
    async function fetchToken() {
      const fetchTokenResp = await fetch(`${API_URL}/users/me`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': "application/json",
        },
        signal: Timeout(7).signal
      });
      if (fetchTokenResp.ok) {
        fetchTokenResp.json().then((body) => { setToken(body); setTokenStatus(true); });
      }
    }
    fetchToken();
  }, [])

  return (
    <Router>
      <Routes>
        <Route path='/' element={<PrivateRoute tokenStatus={tokenStatus} setToken={setToken} setTokenStatus={setTokenStatus}> <Layout tokenStatus={tokenStatus} /> </PrivateRoute>} />
        <Route path='/profile' element={<PrivateRoute tokenStatus={tokenStatus}> <Profile token={token} /> </PrivateRoute>} />
        <Route path='/viewer' element={<PrivateRoute tokenStatus={tokenStatus}> <ViewerProvider> <MainViewer /> </ViewerProvider></PrivateRoute>} />
        <Route path="*" element={<FourOFour />} />
      </Routes>
    </Router>
  );
}

export default App;