import React, { useEffect, useState, useRef } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import axios from "axios";

import '../../assets/css/Loader.css'
import '../../assets/css/Viewer.css';
import { Background } from "../public/styled";
import { Column, Img, Row, StyledDiv } from "./styled";

import logo from "../../assets/svg/ViewerLogo.svg";
import Lidar from "../../assets/svg/ViewLidar.svg";
import Camera360 from "../../assets/svg/ViewCamera360.svg";
import LidarActive from "../../assets/svg/ViewLidarActive.svg";
import Camera360Active from "../../assets/svg/ViewCamera360Active.svg";
import ExpendIcon from "../../assets/svg/expendIcon.svg"
import CollapseIcon from "../../assets/svg/collapseIcon.svg"
import closeIcon from "../../assets/svg/close.svg";

import ViewerBar from "../../components/ViewerBar/ViewerBar";
import MapViewer from "../../components/MapComponent/MapComponent";
import PanoramaViewer from "../../components/PanoramaComponent/PanoramaComponent";
import PointCloudViewer from "../../components/PointCloudComponent/PointCloudComponent";

import { SensorType } from "../../api/Enums";
import { IScanSession } from "../../api/Interfaces";
import { Timeout } from "../public/Login";
import { findPropFromValue } from "../../utilities/helper_misc";
import { useViewer } from "../../viewerContext";

const API_URL: string | undefined = process.env.REACT_APP_API_URL;
const CDN_URL: string | undefined = process.env.REACT_APP_CDN_URL;


export default function MainViewer(props: any) {
  const navigate = useNavigate();
  const location: any = useLocation();

  const [error, setError] = useState<any>(null);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const { viewerState, setViewerState } = useViewer();

  const [activeView, setActiveView] = useState("panorama");
  const [view, setView] = useState("panorama");

  const scanSessionCRS: string = location.state.crs;
  const toSensorType: string = location.state.toSensorType;
  const scanSessionObject: IScanSession = location.state.scanSession;
  const scanSessionDate: string = DateTime.fromISO(scanSessionObject.date.toString()).toFormat('dd LLLL yyyy');
  const scanSessionFolder: string = DateTime.fromISO(scanSessionObject.date.toString()).toFormat('yyyyLLdd_HHmmss');

  const potreeChildRef = useRef<any>();

  // let sensor_camera_pano = findPropFromValue(scanSessionObject.sensor_scans, {type: SensorType.panoramic_camera }, 'id');
  // let sensor_lidar = findPropFromValue(scanSessionObject.sensor_scans, {type: SensorType.lidar}, 'id');

  useEffect(() => {
    if ((!scanSessionCRS) || (!scanSessionObject.id) || (!scanSessionObject.date) || (!scanSessionObject.type))
      navigate('*');
  }, [])

  const handleSwitchPC2Pano = async (
    updatedPointCloudLoc: any,
  ) => {
    axios
      .get(
        `${API_URL}/scansessions/${scanSessionObject.id}/${scanSessionCRS}?center=${updatedPointCloudLoc.x}&center=${updatedPointCloudLoc.y}&to_sensor_type=${toSensorType}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const newCameraFrame = response.data;

        if (newCameraFrame[0] !== viewerState.dataId + 1) {
          setViewerState({ type: 'UPDATE_FROM_POINTCLOUD', payload: { id: newCameraFrame[0] } });
        }

      });
  };

  const SwitchNav = () => {
    return (
      <Navbar className="switch-nav" variant="dark">
        <Container>
          <Nav className="me-auto">
            <StyledDiv onClick={(e) => { setActiveView("panorama"); setView("panorama"); if (activeView === "lidar") { handleSwitchPC2Pano(potreeChildRef.current.getAlert()); } }}
              css="float: right;
                                flex: 50%;
                                padding: 10px 10px 10px 0px;
                                width: auto;"
            >
              {
                activeView === "panorama" &&
                <Img src={Camera360Active} />
              }
              {
                activeView !== "panorama" &&
                <Img src={Camera360} />
              }
            </StyledDiv>
            <StyledDiv onClick={(e) => { setActiveView("lidar"); setView("lidar"); }}
              css="float: right;
                                flex: 50%;
                                padding: 10px 10px 10px 10px;
                                width: auto;">
              {
                activeView === "lidar" &&
                <Img src={LidarActive} />
              }
              {
                activeView !== "lidar" &&
                <Img src={Lidar} />
              }
            </StyledDiv>
            <StyledDiv
              css="float: right;
                                flex: 50%;
                                padding: 10px 10px 10px 10px;
                                width: auto;">


              <Link to="/"><Img src={closeIcon} /></Link>
            </StyledDiv>
          </Nav>
        </Container>
      </Navbar>
    )
  }

  const ViewerNav = () => {
    return (
      <StyledDiv css="position: absolute;
                              display: flex;
                              justify-content: space-between;
                              padding-right: 15%;
                              min-width: 100%;
                          ">
        <Img css="padding: 10px; z-index:3;" src={logo} />
        <ViewerBar ssd={scanSessionDate} />
        <SwitchNav />
      </StyledDiv>
    )

  }

  useEffect(() => {
    const fetchData = async function (id: number) {
      const response = await fetch(`${API_URL}/scansessions/${id}`, {
        headers: {
          'Content-Type': "application/json",
          'sec-fetch-mode': "no-cors"
        },
        method: 'GET',
        credentials: 'include',
        signal: Timeout(7).signal
      });
      const respData = await response.json()
      if (scanSessionObject.id === 1) {
        setViewerState({
          type: "INIT",
          payload: { data: respData, override_start_id: 588 }
        })
      }
      else {
        setViewerState({
          type: "INIT",
          payload: { data: respData, override_start_id: null }
        })
      }
      setIsDataReady(true);
    }
    fetchData(scanSessionObject.id).catch((error) => { setError(error) });
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  else {
    if (!isDataReady) {
      return (
        <Background>
          <div className="loader" style={{ "top": "45%", "left": "47%" }}><div></div><div></div><div></div><div></div></div>
        </Background>
      );
    }
    else {
      return (
        <Background css="min-width: 1200px">
          <Row>
            <Column>
              <Column css="display: flex; justify-content: space-between; margin:auto; margin-left: 0;">
                {viewerState.mapExpanded &&
                  <Column xs={3}>
                    <MapViewer map_style={{ "height": "100vh" }} />
                  </Column>
                }
                <ViewerNav />
                <Column>
                  <StyledDiv className="maptoggle-container" onClick={() => setViewerState({ type: 'TOGGLE_MAP' })}>
                    {viewerState.mapExpanded &&
                      <Img className="maptoggle-image" src={CollapseIcon} />}
                    {!viewerState.mapExpanded &&
                      <Img className="maptoggle-image" src={ExpendIcon} />}
                  </StyledDiv>
                  <div className="viewer-panorama">
                    {view === "panorama" && <PanoramaViewer url={`${CDN_URL}/${scanSessionFolder}/360/1/`} sensorScanId={2} />}
                  </div>
                  <div className="viewer-pointcloud">
                    {view === "lidar" && <PointCloudViewer url={`${CDN_URL}/${scanSessionFolder}/pointclouds`} num_pc={6} ref={potreeChildRef} />}
                  </div>
                </Column>
              </Column>
            </Column>
          </Row>
        </Background>
      );
    }
  }
}