import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import SidebarLogo from "../../assets/svg/SidebarLogo.svg"
import { StyledDiv } from '../../views/private/styled';
import { Timeout } from "../../views/public/Login";

const API_URL: string | undefined = process.env.REACT_APP_API_URL;

function Navbar(props: any) {
    const [active, setActive] = useState("Dashboard");
    const [hover, setHover] = useState("");
    const getIcon = (item: any) => {
        return ((item.title === hover || item.title === active) ? item.activeIcon : item.icon)
    }

    const logoutUser = () => {
        fetch(`${API_URL}/auth/jwt/logout`, {
            method: 'POST',
            credentials: 'include',
            signal: Timeout(7).signal
        })
        .then(data => {
            window.location.reload();
        })
        ;
    }

    return (
        <nav className='nav-menu'>
            <ul className='nav-menu-items'>
                <Link className='sidebarLogo' to='/'>
                    <img src={SidebarLogo} alt='W360' />
                </Link>
                <div className='all-nav-items'>
                    {SidebarData.map((item, index) => {
                        return (
                            <li onClick={(e) => setActive(item.title)}
                                onMouseEnter={() => setHover(item.title)}
                                onMouseLeave={() => setHover("")}
                                key={index} className={item.cName}>
                                {item.isExternalPath ?
                                    <a href={item.path} target={item.target}>
                                        {getIcon(item)}
                                    </a> :
                                    <Link to={{ pathname: item.path }} target={item.target}>
                                        {getIcon(item)}
                                    </Link>}
                            </li>)
                    })}
                    <StyledDiv onClick={logoutUser} className="logoutIconCss">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" viewBox="0 0 24 23" fill="none" strokeWidth="2.5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                    </StyledDiv>
                </div>
            </ul>
        </nav>
    );
}

export default Navbar;
