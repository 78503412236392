import { useNavigate } from "react-router-dom";
import Moment from 'moment';

import { Column, Img, ListItem, Row, Text } from "./styled";
import DoubleSideArrow from "../../assets/svg/DoubleSideArrow.svg"

import SensorScan from "./SensorScan";
import { IScanSession } from "../../api/Interfaces";


const ScanSession = (scanSession: IScanSession) => {

    let navigate = useNavigate();
    const handleScanClick = () => {
        navigate(`/viewer`, { state: { scanSession, crs: "sweden", toSensorType: "360_CAMERA" } });
    }

    return (
        <ListItem onClick={() => handleScanClick()}
            css="
        border-radius: 15px;
        padding: 15px;
        margin-bottom: 10px;
        background-color: 
        #323644;
        &: hover {
            background-color: #323644;
            border: solid 1px #1FC7A5;
        ">
            <div>
                <Row xs={6} md={6} lg={6}>
                    <Column xs={6} md={6} lg={6} css="display: flex; justify-content: space-between; margin:auto; margin-left: 0;">
                        <Text css="margin: 0px; text-size-adjust:80%">
                            {scanSession.sites[0].location}
                        </Text>
                        <Img css="
                            margin: 3px;
                            "
                            alt=""
                            src={DoubleSideArrow}
                            className="d-inline-block align-top"
                            />
                        <Text css="margin: 2px;">
                            {scanSession.sites[scanSession.sites.length - 1].location}
                        </Text>        
                    </Column>
                    {scanSession.sensor_scans.map((sensorScan) => (
                        <Column css="display: flex; justify-content: space-between; margin:auto;" xs={1} md={1} lg={1} key={sensorScan.id}>
                            <SensorScan {...sensorScan} />
                        </Column>
                    ))}
                    <Column xs={3} md={3} lg={3} css="margin-left: auto; 
                                                        margin-right: 0;" >
                        <Text css="margin: auto;">{Moment(scanSession.date).format('DD MMM yyyy')}</Text>
                    </Column>
                </Row>
            </div>
        </ListItem>
    )
}
export default ScanSession;
