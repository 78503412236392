import { useEffect, useRef } from 'react';
import { MutableRefObject } from 'react';
import { useViewer } from "../../viewerContext"

import { round_sig2 } from '../../utilities/helper_math';

import "mapbox-gl/dist/mapbox-gl.css";

import mapboxgl from 'mapbox-gl';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function MapViewer(props: any) {
    
    const { viewerState, setViewerState } = useViewer();

    const mapContainer = useRef<any>(null);
    const map = useRef() as MutableRefObject<mapboxgl.Map>;

    useEffect(() => {
        if (map.current) return;

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [14.973435, 57.663611],
            zoom: 20,
            accessToken: "pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA",
        });

        map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

        map.current.on("load", () => {

            map.current.loadImage(
                "/img/marker-icon-circle-20px-blue.png",
                function (error: any, image: any) {
                    if (error) throw error;
                    map.current.addImage("inactive-marker", image);
                }
            );

            map.current.loadImage(
                "/img/marker-icon-20px-red.png",
                function (error: any, image: any) {
                    if (error) throw error;
                    map.current.addImage("active-marker", image);
                }
            );

            var popup: mapboxgl.Popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
            });

            map.current.on("mouseenter", "points", (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
                let coordinate = e.features[0].geometry.coordinates.slice();
                let e_attr = JSON.parse(e.features[0].properties.attributes);

                while (Math.abs(e.lngLat.lng - coordinate[0]) > 180) {
                    coordinate[0] += e.lngLat.lng > coordinate[0] ? 360 : -360;
                }
                popup.setLngLat(coordinate).setHTML(`<p style="color:black">RailPos: [${e_attr[4]} km + ${round_sig2(parseFloat(e_attr[5]))} m]<br />Bandel: ${e_attr[6]}</p>`).addTo(map.current);
            });

            map.current.on("mouseleave", "points", () => {
                popup.remove();
            });

            map.current.on("click", "points", (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
                let e_samples = JSON.parse(e.features[0].properties.samples);
                let e_id: number = e.features[0].id;

                function findpano(item: any) {
                    if (item.type === "360_CAMERA") e_id = item.id;
                }

                e_samples.forEach(findpano);

                map.current.flyTo({
                    center: e.lngLat,
                });
                setViewerState({ type: "UPDATE_FROM_MAP", payload: { id_map: e.features[0].id, id_pano: e_id } });

                map.current.setLayoutProperty("points", "icon-image", [
                    "match",
                    ["get", "id"],
                    e_id,
                    "active-marker",
                    "inactive-marker",
                ]);

            });

            map.current.addSource("session", {
                type: "geojson",
                data: viewerState.data,
                promoteId: "id",
            });

            map.current.addLayer({
                id: "points",
                type: "symbol",
                source: "session",
                layout: {
                    "icon-image": "inactive-marker",
                },
            });

            map.current.setLayoutProperty("points", "icon-image", [
                "match",
                ["get", "id"],
                viewerState.dataId,
                "active-marker",
                "inactive-marker",
            ]);
        });
    }, []);

    return (
        <div id="mapbox_render_area" ref={mapContainer} style={props.map_style} />
    );
}
