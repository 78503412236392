import { useViewer } from "../../viewerContext";

import { Container, Nav, Navbar } from "react-bootstrap";
import { Column, NavTitleText, NavValueText } from "../../views/private/styled";

export default function ViewerBar(props) {
  const { viewerState } = useViewer();

  return (
    <Navbar className="nav-bar" bg="" variant="dark">
      <Container>
        <Nav
          className="me-auto"
          style={{
            display: "flex",
            justifyContent: "spaceBetween",
            width: "100%",
          }}
        >
          <Column xs={2}>
            <NavTitleText>Section</NavTitleText>
            <NavValueText>
              {
                viewerState.data.features[viewerState.dataId].properties
                  .attributes[6]
              }
            </NavValueText>
          </Column>
          <Column xs={2}>
            <NavTitleText>Km</NavTitleText>
            <NavValueText>
              {
                viewerState.data.features[viewerState.dataId].properties
                  .attributes[4]
              }
            </NavValueText>
          </Column>
          <Column xs={3}>
            <NavTitleText>M</NavTitleText>
            <NavValueText>
              {
                viewerState.data.features[viewerState.dataId].properties
                  .attributes[5]
              }
            </NavValueText>
          </Column>
          <Column
            xs={5}
            style={{ borderLeft: "solid 2px rgba(154, 165, 181, 0.5)" }}
          >
            <NavTitleText>Date:</NavTitleText>
            <NavValueText>{props.ssd}</NavValueText>
          </Column>
        </Nav>
      </Container>
    </Navbar>
  );
}
