import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import { Background } from '../public/styled'

export default function Profile( props: any ) {
    
  return (
      <>
      <Sidebar />
      <Background>
      <h2 style={{"position": "fixed", "left": "45%", "top": "10%"}}>Profile</h2>
      <h5>{props.token.id}</h5>
      <h5>{props.token.name}</h5>
      </Background>
      </>
  )
}
