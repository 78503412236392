import React from 'react'
import { Background } from './styled'

export default function FourOFour() {
  return (
    <Background >
        <div style={{"position": "fixed", "left": "45%", "top": "10%"}}>
        <h1 style={{"color": "#1CB797", "fontSize": "80px"}}>404</h1>
        <p style={{"fontSize": "30px"}}>Not found!!</p>
        </div>
    </Background>
  )
}
