import styled from "styled-components";



export const Background = styled.div<{ css?: string }>`
${(props) => props.css ?? ""};
font-family: "Montserrat", Aerial, sans-serif;
font-style: normal;
color: #FFFFFF;
position: relative;
background-size: cover;
height: 100vh;
min-height: 100%;
overflow: auto;
background-color: rgba(39, 42, 55, 1);
`;


export const ImgOverLay = styled.div`
z-index: 5;
position: fixed;
width: 100%;
height: 100vh;
min-height: 100%;
background-image:
linear-gradient(79deg, rgba(39, 42, 55, 1) 55%,
                        rgba(39, 42, 55, 0.9) 70%,
                        rgba(39, 42, 55, 0.8) 80%,
                        rgba(39, 42, 55, 0) 100%);

`;

export const StyledImg = styled.img<{ css?: string }>`
${(props) => props.css ?? ""};
top:0;
min-height: 100%;
z-index: 1;
height: 100vh;
min-height: 100%;
position: fixed;
right: 0;
`;


export const LoginFormDiv = styled.div`
padding-bottom: 208px;
margin: 225px 0px 0px 93px ;
width: 495px;
z-index: 9;
position: absolute;

`;

export const RememberMeText = styled.p`
font-weight: 800;
font-size: 20px;
color: #9AA5B5;

`;

export const LogInTag = styled.p`
font-weight: 800;
font-size: 60px;
line-height: 74px;

color: #FFFFFF;

`;

export const NoAccoutText = styled.p`
font-weight: 400;
font-size: 16px;
color: #9AA5B5;
padding-bottom: 56px;
`;

export const BigPoint = styled.span`
color:#1CB797;
font-size: 150%;
`;

export const StyledButton = styled.button`
float: right;
width: 110px;
height: 41px;
left: 322px;
top: 213px;
background: #1CB797;
border-radius: 15px;
font-weight: 800;
font-size: 20px;
color: #FFFFFF;
`;

export const Img = styled.img<{ css?: string }>`
${(props) => props.css ?? ""};
`;