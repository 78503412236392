import styled from "styled-components";


import {
    Col,
    Row as BSROW,
    Container, Accordion
} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';

export const List = styled.div<{ css?: string }>`
${(props) => props.css ?? ""};
`;

export const Row = styled(BSROW)<{ css?: string }>`
	${(props) => props.css ?? ""};
`;

export const Column = styled(Col)<{ css?: string }>`
	${(props) => props.css ?? ""};
`;

export const ListItem = styled(Container)<{ css?: string }>`
	${(props) => props.css ?? ""};
`;

export const SubListItem = styled.div<{ css?: string }>`
	${(props) => props.css ?? ""};
`;

export const Text = styled.p<{ css?: string }>`
	${(props) => props.css ?? ""};

`;

export const ListTitle = styled.p<{ css?: string }>`
	font-weight: 800;
	font-size: 35px;
	padding: 10px 0;
	margin-bottom: 10px;
	background-color: rgba(39, 42, 55, 1);
	border-radius: 15px 15px 0 0;
	text-align: center;
	${(props) => props.css ?? ""};
`;

export const Img = styled.img<{ css?: string }>`
	${(props) => props.css ?? ""};
`;

export const Accor = styled.div<{ css?: string }>`
	${(props) => props.css ?? ""};
	background-color: #fff5;
`;

export const OpenedScan = styled.div<{ css?: string }>`
	${(props) => props.css ?? ""};                    
`;

export const StyledDiv = styled.div<{ css?: string }>`
	${(props) => props.css ?? ""};
`;

export const NavTitleText = styled.p<{ css?: string }>`
	${(props) => props.css ?? ""};
	margin: auto;
	text-align: center;
	width: 42.78px;
	height: 15.56px;
	font-weight: 800;
	line-height: 16px;
	color: #9AA5B5;
`;

export const NavValueText = styled.p<{ css?: string }>`
	${(props) => props.css ?? ""};
	margin: auto;
	text-align: center;
	font-weight: 800;
	font-size: auto;                            
	color: #FFFFFF;
`;

export const MapText = styled.div<{ css?: string }>`
	${(props) => props.css ?? ""};
`;