export enum ScanSessionType {
    railway = "railway",
    road = "road",
    aerial = "aerial",
}

export enum SensorType {
    lidar = "LIDAR",
    camera = "CAMERA",
    panoramic_camera = "360_CAMERA",
    ins = "INS",
}