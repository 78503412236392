declare global {
    interface Window {
        proj4: any;
    }
}
const proj4 = window.proj4;

proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
proj4.defs("EPSG:3006", "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

const defined_projections: Array<string> = ['EPSG:4326', 'EPSG:3006']

export function transform_coordinate(coord: Array<number>, from: string, to: string, dim: number) {
    if (defined_projections.includes(from)) {

        if (defined_projections.includes(to)) {

            let coord_len = coord.length
            if ((dim === 3) && (coord_len === 3)) {
                return proj4(from, to).forward(coord);
            }
            else if ((dim === 3) && (coord_len === 2)) {
                let temp_coord = proj4(from, to).forward(coord);
                temp_coord.push(220);
                return temp_coord;
            }
            else if ((dim === 2) && (coord_len === 2)) {
                return proj4(from, to).forward(coord);
            }
            else if ((dim === 2) && (coord_len === 3)) {
                let temp_coord = proj4(from, to).forward(coord);
                return [temp_coord[0], temp_coord[1]];
            }
            else {
                // console.log('Error in Coordinate Transformations!', '\nFrom: ', from, 'To: ', to, '\nReturn Dimensions: ', dim, 'Input Coordinates: ', coord)
            }
        }
        else {
            // console.log('Error: Wrong {to} value');
            return;
        }
    }
    else {
        // console.log('Error: Wrong {from} value');
        return;
    }

}